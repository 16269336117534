import React, { Component } from "react";
import { Segment, Divider, Form, Message, Grid } from "semantic-ui-react";
import { Field, SubmissionError } from 'redux-form';
import axios from 'axios';
import _ from 'lodash';
import { bindActionCreators } from "redux";
import modules from "../common/modules";
import { actionCreators } from "../../store/User";
import { connect } from "react-redux";
import AssignServiePlanToUser from "../forms/assign-service-plan-to-user";
import { RenderField, RenderDateField, renderToggleInput } from '../common/renderField';
import toggles from "../common/toggles";
const { REACT_APP_API_URL } = process.env;


class UserSubscriptionDetail extends Component {
  state = {
    subscriptionDetails: {},
    user: {},
    userDetails: {},
    loading: false,
    servicePlans: [],
    success: false,
    site: parseInt(localStorage.getItem('site'))
  }
  constructor(props) {
    super(props);
  }
  servicePlans = async () => {
    try {
      let site = parseInt(localStorage.getItem('site'));
      let servicePlans
      let URL;
      if (site == 4) {
        URL = process.env.REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL
      } else {
        URL = REACT_APP_API_URL
      }
      servicePlans = await axios.get(`${URL}/service-plans?site=${site}`);
      servicePlans = _.get(servicePlans, 'data');
      this.setState({ servicePlans: servicePlans, site: site });
    } catch (err) {
      console.log("=========err", err)
    }
  }

  servicePlanOptions = () => {
    const { servicePlans } = this.state;

    let options = servicePlans.map(plan => {
      return { text: _.get(plan, 'plan_name'), value: _.get(plan, 'id') };
    });

    options.unshift({ text: 'Select One', value: '' });

    return options;
  }

  subscriptionDetails = async () => {
    this.setState({ loading: true })
    let userId = this.props.match.params.id;
    try {
      let site = parseInt(localStorage.getItem('site'));
      let user = await axios.get(`${REACT_APP_API_URL}/users/${userId}?site=${site}`);

      if (user.data) {
        user = _.get(user, 'data');
        let subscriptionDetails = _.get(user, 'userServicePlan');
        subscriptionDetails.plan_name = _.get(user, 'plan.name');
        subscriptionDetails.service_plan_name = _.get(user, 'servicePlan.plan_name');
        subscriptionDetails.service_plan_id = _.get(user, 'servicePlan.id');
        this.setState({ subscriptionDetails: subscriptionDetails, user: user });
        this.setState({ loading: false })
      }
      else {
        this.props.history.push(`/users`);
        this.setState({ loading: false })

      }

    } catch (err) {
      this.setState({ loading: false })

    }
  }
  setValues_q = (servicePlan) => {
    console.log("ddddddddddddddddddddddd",servicePlan)
    let states = Object.assign({}, this.state);

    let keys = Object.keys(modules);

    let moduleData = keys.map(k => {
      states.subscriptionDetails[k] = servicePlan[k];
    });

    states.subscriptionDetails.service_plan_id = servicePlan.id;
    this.setState(states);

  }

  setValues = (servicePlan) => {
    console.log("ServicePlan received:", servicePlan);
  
    let states = Object.assign({}, this.state);
  
    if (!servicePlan) {
      // If servicePlan is empty, set default values
      states.subscriptionDetails = {
        service_plan_id: '', // Default empty service_plan_id
        ...Object.keys(modules).reduce((acc, key) => {
          acc[key] = null; // Set module details to null or default value
          return acc;
        }, {}),
      };
    } else {
      // Handle valid servicePlan
      let keys = Object.keys(modules);
  
      keys.forEach(key => {
        states.subscriptionDetails[key] = servicePlan[key];
      });
  
      states.subscriptionDetails.service_plan_id = servicePlan.id;
    }
  
    this.setState(states);
  };

  onSelect = (selectedId) => {
    console.log("selectedId===", selectedId);
    let servicePlan = _.find(this.state.servicePlans, (plan) => plan.id == selectedId);
    this.setValues(servicePlan);
  }
  componentDidMount = async () => {
    await this.subscriptionDetails();
    await this.servicePlans();
    await this.getUserDeatials();
  }


  getUserDeatials = async () => {
    let userId = this.props.match.params.id;
    let site = parseInt(localStorage.getItem('site'));
    let userData = await axios.get(`${REACT_APP_API_URL}/users/${userId}?site=${site}`); 
    this.setState({userDetails: userData });
    return userData;
  }


  handleSubmit = async (userServicePlan) => {
    try { 
      let userId = this.props.match.params.id;
      let servicePlanId = userServicePlan.service_plan_id;
      this.setState({ loading: true }); 

      let servicePlanUpdated = await axios.post(`${REACT_APP_API_URL}/users/assign-service-plan-to-user/${userId}`, { "service_plan_id": servicePlanId });
      servicePlanUpdated = _.get(servicePlanUpdated, 'data');
      this.setState({ loading: false, success: true });
      window.location.href= '/users';

    } catch (err) {
      this.setState({ loading: false });
      throw SubmissionError({ plan_name: 'Issuee' });
    }
  };

  loadModules = (disabled = false) => {

    let keys = Object.keys(modules);
    let moduleData = keys.map(k => {
      return <Grid.Column>
        <Field name={k} component={RenderField} as={Form.Checkbox} label={modules[k]} disabled={disabled} />
      </Grid.Column>;
    });
    return <Grid columns='6' style={{ minHeight: '5vh' }}>{moduleData}</Grid>;
  }

  loadToggles = (disabled = false) => {
    let keys = Object.keys(toggles)
    let toggleData = keys.map(k => {
      return (
        <Grid.Column>
          <label style={{ display: 'block' }}>{toggles[k]}</label>
          <Field name={k} component={renderToggleInput} label={toggles[k]} as={Form.Checkbox} disabled={disabled} />
        </Grid.Column>
      )
    })
    return <Grid columns='6' style={{ minHeight: '5vh' }}>{toggleData}</Grid>
  }

  render() {
    const { success, site, userDetails } = this.state; 
    return (
      <Segment>
        <h1>Assign Service Plan{site !== 4 && `${this.state.user.first_name} ${this.state.user.last_name}`}<span style={{ fontSize: 14, color: 'green', marginLeft: 10 }}>{success && 'Details updated!!'}</span></h1>
        <Segment>
          <AssignServiePlanToUser site={site} onSelect={this.onSelect} servicePlans={this.servicePlanOptions()} loading={this.state.loading} onSubmit={this.handleSubmit} loadModules={this.loadModules} loadToggles={this.loadToggles} initialValues={{ service_plan_id: this.state.userDetails?.data?.service_plan_id }} />
        </Segment>
      </Segment>
    );
  }
}


export default connect(
  state => {
    const { User, ServicePlan } = state;
    return {
      state: { User, ServicePlan }
    };
  },
  dispatch => bindActionCreators(actionCreators, dispatch)
)(UserSubscriptionDetail);
