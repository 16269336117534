import React, { Component } from "react";
import {
  Button,
  Divider,
  Input,
  Form,
  Header
} from "semantic-ui-react";
import { Field, reduxForm } from 'redux-form';
import { RenderField, RenderDateField } from '../common/renderField';
import plans from '../common/plans';
import LoaderComponent from "../common/LoaderComponent";


class UserSubrscriptionDetailForm extends Component {

  render(){

	  const { handleSubmit, loadModules, loading, servicePlans, onSelect, site, loadToggles } = this.props;

	  console.log("Site ==== ",site);

	  return (<Form onSubmit={handleSubmit}>
	           <label>Plan Name</label>
	           <Header style={{marginTop:0}} as='h5' block>{ this.props.initialValues.plan_name }</Header>
	           <label>Service Plan Name</label>
	           <Field onChange={onSelect} name="service_plan_id" as={Form.Select} fluid lightweight options={servicePlans} component={RenderField} placeholder='Select Service Plan' width={16} disabled={site === 4 || false}/>
	           {site == 1 && <>
	          <label>Total Number Of Events</label>
	            <Field name="event_number" component={RenderField} placeholder="Total Number Of Events" as={Form.Input} required/>

	          <label>Total Number Of Snaps</label>
	            <Field name="snap_number" component={RenderField} placeholder="Total Number Of Snaps" as={Form.Input} required/>

	          <label>Total Number Of Bg Removals</label>
	            <Field name="bg_removal_number" component={RenderField} placeholder="Total Number Of Bg Removals" as={Form.Input} required/>

	           <label>Select Frame Plan</label>
	           <Field name="frame_plan" as={Form.Select} fluid lightweight options={plans} component={RenderField} placeholder='Select Frame Plan' width={16} />
	           
	           <label>Select Sticker Plan</label>
	           <Field name="sticker_plan" as={Form.Select} fluid lightweight options={plans} component={RenderField} placeholder='Select Sticker Plan' width={16} />
	           
	           <label>Select Background Plan</label>
	           <Field name="background_plan" as={Form.Select} fluid lightweight options={plans} component={RenderField} placeholder='Select Background Plan' width={16} />
	           </>}
	           {site == 2 && <>
	           <label>Max Minutes</label>
	           <Field name="max_mintues" component={RenderField} placeholder="Max Minutes" as={Form.Input} required/>

	           <label>Max Steps</label>
	           <Field name="max_step" component={RenderField} placeholder="Max Steps" as={Form.Input} required/>
            </>}
	           {site == 4 && !loading ? <>
				<label>Number of Projects</label>
				<Field name="noOfXPProjects" component={RenderField} placeholder="Number of Projects" as={Form.Input} required disabled={true}/>

				<label>Number of Contacts</label>
				<Field name="noOfXPContacts" component={RenderField} placeholder="Number of Contacts" as={Form.Input} required disabled={true} />

				<label>Campaigns No./ Project</label>
				<Field name="noOfXPCampaigns" component={RenderField} placeholder="Campaigns No./ Project" as={Form.Input} required disabled={true} />

				<label>Sessions No./ Project</label>
				<Field name="noOfXPSessions" component={RenderField} placeholder="Sessions No./ Project" as={Form.Input} required disabled={true} />

				<label>Notifications No./ Project</label>
				<Field name="noOfXPNotifications" component={RenderField} placeholder="Notifications No./ Project" as={Form.Input} required disabled={true} />

				<label>Attendees No./ Project</label>
				<Field name="noOfXPAttendees" component={RenderField} placeholder="Attendees No./ Project" as={Form.Input} required disabled={true} />

				<label>Custom Fields No./ Project</label>
				<Field name="noOfXPCustomFields" component={RenderField} placeholder="Custom Fields No./ Project" as={Form.Input} required disabled={true} />

				<label>Challenges No./ Project</label>
				<Field name="noOfXPChallenges" component={RenderField} placeholder="Challenges No./ Project" as={Form.Input} required disabled={true} />

				<label>Pages No./ Project</label>
				<Field name="noOfXPPages" component={RenderField} placeholder="Pages No./ Project" as={Form.Input} required disabled={true} />

				<label>Team Members No./ Project</label>
				<Field name="noOfXPTeamMembers" component={RenderField} placeholder="Team Members No./ Project" as={Form.Input} required disabled={true} />
	           
            </> : <LoaderComponent/>}
             <label>Modules</label>
	            {loadModules(true)}
				{site == 4 && loadToggles(true)}
	           <Divider/>
	           {site !== 4 &&<Button primary type="submit" loading={loading}>Submit</Button>}
	          </Form>)
	}
}

UserSubrscriptionDetailForm = reduxForm({
  form: 'userSubrscriptionDetailForm',
  enableReinitialize : true
})(UserSubrscriptionDetailForm)

export default UserSubrscriptionDetailForm;