import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router'
import Layout from '.././layout/Layout'
import Home from '.././pages/Home'
import User from '.././pages/User'
import AddUser from '.././pages/AddUser'
import EditUser from '.././pages/EditUser'
import UserSubrscriptionDetail from '.././pages/UserSubrscriptionDetail'
import AssignServiePlanToUser from '.././pages/AssignServiePlanToUser'
import CardForm from '.././pages/Card'
import ServicePlan from '.././pages/ServicePlan'
import PricingPlan from '.././pages/PricingPlan'
import AddServicePlan from '.././pages/AddServicePlan'
import EditServicePlan from '.././pages/EditServicePlan'
import Login from '.././pages/Login'
import DsServicePlanMapping from '.././pages/DsServicePlanMapping'
import Frame from '.././pages/Frame'
import Sticker from '.././pages/Sticker'
import Background from '.././pages/Background'
import Product from '.././pages/Product'
import ProductService from '.././pages/ProductService'
import AddProductService from '.././pages/AddProductService'
import EditProductService from '.././pages/EditProductService'
import ProjectTemplates from '../pages/templates/ProjectTemplates';
import PageTemplates from '../pages/templates/PageTemplates';
import CampaignTemplates from '../pages/templates/CampaignTemplates'
import AddProject from '../pages/templates/AddProject'
import AddPage from '../pages/templates/AddPage'
import EditProject from '../pages/templates/EditProject'
import Configuration from '../pages/Configuration'
import ChangeSystemAccount from '../pages/ChangeSystemAccount'
import AddCampaign from '../pages/templates/AddCampaign'
const isAuthenticated = (pageComponent) => {
  let token= localStorage.getItem('authorization');

  if(token) {
    return (<Redirect to={{pathname: '/'}} />);
  }
  return pageComponent;
}
const isNotAuthenticated = (pageComponent) => {
  let token= localStorage.getItem('authorization');

  if(!token) {
    return (<Redirect to={{pathname: '/login'}} />);
  }
  return pageComponent;
}
export default class App extends Component {
 
 render(){
  return (<Switch>
		    <Route path="/login" render={(props) => isAuthenticated(<Login/>)} exact/>
		    <Layout>
		     <Route exact path="/" render={(props) => isNotAuthenticated(<Home {...props}/>)} />
		     <Route path="/users" render={(props) => isNotAuthenticated(<User {...props}/>)} exact/>
         <Route path="/users/add" render={(props) => isNotAuthenticated(<AddUser {...props}/>)} exact/>
         <Route path="/users/:id/edit" render={(props) => isNotAuthenticated(<EditUser {...props}/>)} exact/>
		     <Route path="/users/:id/subscription" render={(props) => isNotAuthenticated(<UserSubrscriptionDetail {...props}/>)} exact/>
		     <Route path="/users/:id/assign-servie-plan-to-user" render={(props) => isNotAuthenticated(<AssignServiePlanToUser {...props}/>)} exact/>
         <Route path="/service-plans" render={(props) => isNotAuthenticated(<ServicePlan {...props}/>)} exact/>
		     <Route path="/service-plans/add" render={(props) => isNotAuthenticated(<AddServicePlan {...props}/>)} exact/>
         <Route path="/pageTemplate/add" render={(props) => isNotAuthenticated(<AddPageTemplate {...props}/>)} exact/>
		     <Route path="/service-plans/:id/edit" render={(props) => isNotAuthenticated(<EditServicePlan {...props}/>)} exact/>
         <Route path="/pricing-plans" render={(props) => isNotAuthenticated(<PricingPlan {...props}/>)} exact/>
         <Route path="/ds-service-plan-mapping" render={(props) => isNotAuthenticated(<DsServicePlanMapping {...props}/>)} exact/>
         <Route path="/frames" render={(props) => isNotAuthenticated(<Frame {...props}/>)} exact/>
         <Route path="/stickers" render={(props) => isNotAuthenticated(<Sticker {...props}/>)} exact/>
         <Route path="/backgrounds" render={(props) => isNotAuthenticated(<Background {...props}/>)} exact/>
         <Route path="/credits" render={(props) => isNotAuthenticated(<Product {...props}/>)} exact/>
         <Route path="/credits-configuration" render={(props) => isNotAuthenticated(<ProductService {...props}/>)} exact/>
         <Route path="/credits-configuration/add" render={(props) => isNotAuthenticated(<AddProductService {...props}/>)} exact/>
         <Route path="/credits-configuration/:id/edit" render={(props) => isNotAuthenticated(<EditProductService {...props}/>)} exact/>
         <Route path="/project-templates" render={(props) => isNotAuthenticated(<ProjectTemplates {...props}/>)} exact/>
         <Route path="/page-templates" render={(props) => isNotAuthenticated(<PageTemplates {...props}/>)} exact/>
         <Route path="/campaign-templates" render={(props) => isNotAuthenticated(<CampaignTemplates {...props}/>)} exact/>
         <Route path="/project-templates/edit" render={(props) => isNotAuthenticated(<AddProject {...props}/>)} exact/>
         <Route path="/project-templates/add" render={(props) => isNotAuthenticated(<AddProject {...props}/>)} exact/>
         <Route path="/page-templates/add" render={(props) => isNotAuthenticated(<AddPage {...props}/>)} exact/>
         <Route path="/page-templates/edit" render={(props) => isNotAuthenticated(<AddPage {...props}/>)} exact/>
         <Route path="/campaign-templates/add" render={(props) => isNotAuthenticated(<AddCampaign {...props}/>)} exact/>
         <Route path="/campaign-templates/edit" render={(props) => isNotAuthenticated(<AddCampaign {...props}/>)} exact/>
         <Route path="/project-templates/edit/:rowId" render={(props) => isNotAuthenticated(<EditProject {...props}/>)} exact/>
         <Route path="/configuration" render={(props) => isNotAuthenticated(<Configuration {...props}/>)} exact/>
         <Route path="/configuration/change-system-account" render={(props) => isNotAuthenticated(<ChangeSystemAccount {...props}/>)} exact/>
        </Layout>
		  </Switch>)
 }

}
