import React, { Component } from "react";
import { Segment,Divider, Form, Message, Grid} from "semantic-ui-react";
import { Field, SubmissionError } from 'redux-form';
import axios from 'axios';
import _ from 'lodash';
import { bindActionCreators } from "redux";
import modules from "../common/modules";
import { actionCreators } from "../../store/User";
import { connect } from "react-redux";
import UserSubscriptionDetailForm from "../forms/user-subscription-detail-form";
import { RenderField, RenderDateField, renderToggleInput } from '../common/renderField';
import toggles from "../common/toggles";
const { REACT_APP_API_URL }= process.env;
 

class UserSubscriptionDetail extends Component {
  state={
    subscriptionDetails:{},
    user:{},
    loading:false,
    servicePlans:[],
    success:false,
    site: parseInt(localStorage.getItem('site'))
  }
  constructor(props) {
    super(props);
  }
  servicePlans= async () => {
    try{
      let site= parseInt(localStorage.getItem('site'));
      let servicePlans
      let URL;
      if(site==4){
        URL = process.env.REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL
      } else {
        URL = REACT_APP_API_URL
      }
      servicePlans= await axios.get(`${URL}/service-plans?site=${site}`);
      servicePlans= _.get(servicePlans,'data');
      this.setState({servicePlans:servicePlans, site: site});
    }catch(err){
      console.log("=========err", err)
    }
  }

  servicePlanOptions= () =>{
    const { servicePlans } = this.state;

    let options= servicePlans.map(plan => {
        return { text: _.get(plan,'plan_name'), value: _.get(plan,'id')};
    });

    return options;
  }

  subscriptionDetails= async () => {
    this.setState({loading: true})
    let userId= this.props.match.params.id;
    try{
      let site= parseInt(localStorage.getItem('site'));
      let user= await axios.get(`${REACT_APP_API_URL}/users/${userId}?site=${site}`);
     
      if(user.data)
      {
        user= _.get(user,'data');
        let subscriptionDetails = _.get(user,'userServicePlan');
        subscriptionDetails.plan_name= _.get(user,'plan.name');
        subscriptionDetails.service_plan_name= _.get(user,'servicePlan.plan_name');
        subscriptionDetails.service_plan_id= _.get(user,'servicePlan.id');
        this.setState({subscriptionDetails:subscriptionDetails,user:user});
        this.setState({loading: false})
      }
      else{
          this.props.history.push(`/users`);
          this.setState({loading: false})

      }
      
    }catch(err){
      this.setState({loading: false})

    }
  }
  setValues= (servicePlan) => {
    let states= Object.assign({},this.state);
    
    let keys= Object.keys(modules);

    let moduleData = keys.map(k => {
      states.subscriptionDetails[k]= servicePlan[k];
    });

    states.subscriptionDetails.service_plan_id= servicePlan.id;
    this.setState(states);

  }

  onSelect = (selectedId) => {
    let servicePlan= _.find(this.state.servicePlans, (plan) => plan.id == selectedId);
    this.setValues(servicePlan);
  }
  componentDidMount= async () => {
    await this.subscriptionDetails();
    await this.servicePlans();
  }

  handleSubmit = async (userServicePlan) => {
    this.setState({loading:true});

    try {
        let userServicePlanUpdated= await axios.patch(`${REACT_APP_API_URL}/user-service-plans/${userServicePlan.id}`,userServicePlan);
        userServicePlanUpdated= _.get(userServicePlanUpdated,'data');
        this.setState({loading:false,success:true});
    }catch(err){
        this.setState({loading:false});
        throw SubmissionError({plan_name:'Issuee'});
    }
  };
  
  loadModules = (disabled= false) => {

    let keys= Object.keys(modules);
    let moduleData = keys.map(k =>{
        return <Grid.Column>
                <Field name={k} component={RenderField} as={Form.Checkbox} label={modules[k]} disabled={disabled}/>
               </Grid.Column>;
    });
    return <Grid columns='6' style={{minHeight:'5vh'}}>{moduleData}</Grid>;
  }

  loadToggles = (disabled = false) => {
    let keys = Object.keys(toggles)
    let toggleData = keys.map(k => {
      return (
        <Grid.Column>
          <label style={{display: 'block'}}>{toggles[k]}</label>
          <Field name={k} component={renderToggleInput} label={toggles[k]} as={Form.Checkbox} disabled={disabled}/>
        </Grid.Column>
      )
    })
    return <Grid columns='6' style={{ minHeight: '5vh' }}>{toggleData}</Grid>
  }

  render() {
    const { success, site }= this.state;

    return (
      <Segment>
      <h1>Subscription Details{site !== 4 && `${this.state.user.first_name} ${this.state.user.last_name}`}<span style={{ fontSize: 14, color: 'green', marginLeft: 10 }}>{success && 'Details updated!!'}</span></h1>
        <Segment>
          <UserSubscriptionDetailForm site={site} onSelect={this.onSelect} servicePlans={this.servicePlanOptions()} loading={this.state.loading} onSubmit={this.handleSubmit} loadModules={this.loadModules} loadToggles={this.loadToggles} initialValues={this.state.subscriptionDetails} />
        </Segment>
      </Segment>
    );
  }
}


export default connect(
  state => {
    const { User, ServicePlan } = state;
    return {
      state: { User, ServicePlan }
    };
  },
  dispatch => bindActionCreators(actionCreators, dispatch)
)(UserSubscriptionDetail);
