import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import classnames from 'classnames';
import _ from 'lodash';

import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';

import 'react-datepicker/dist/react-datepicker.css';
import Toggle from 'react-toggle';
import "react-toggle/style.css"

export const RenderField = ({
  input,
  label,
  meta: { touched, error },
  as: As = Input,
  lightweight,
  disabled,
  ...props
}) => {
  const onBlur = () => {
    // 'Hack' to handle multiple select boxes - if not present, data is lost on blur
    if (input.multiple) {
      input.onBlur([...input.value]);
    }
  };

  const handleChange = props.control === 'textarea' ? (
    e => {
      return input.onChange(e);
    }
  ) : (
    As === Form.Checkbox ? (
      (e, { checked }) => {
        return input.onChange(checked);
      }
    ) : (
      (e, { value }) => {
        return input.onChange(value);
      }
    )
  );

  const errorMessage = error !== undefined ? error : '';

  // Special functionality for select box (if read-only, as there is no built-in read only functionality)
  if (As === Form.Dropdown && props.readOnly) {
    As = Form.Input;
    _.forOwn(props.options, value => {
      if (value.value === input.value) {
        input.value = value.text;
      }
    });
    delete props.search;
    delete props.options;
    delete props.selection;
    delete props.onSearchChange;
  }

  if (As === Form.Checkbox) {
    props.checked = input.value || false;
    props.value = '';
  }

  if (As === Form.Input && props.type === 'range' && props.readOnly) {
    props.type = 'hidden';
  }

  if (lightweight) {
    return (
      <As
        {...input}
        value={input.value}
        {...props}
        disabled={disabled}
        onChange={handleChange}
        onBlur={onBlur}
        label={label}
        error={touched && error !== undefined}
      />
    );
  } else {
    return (
      <Form.Field
        className={classnames({ error: touched && error !== undefined })}
      >
        <As
          {...input}
          value={input.value}
          {...props}
          disabled={disabled}
          onChange={handleChange}
          label={label}
          error={touched && error !== undefined}
        />
        {touched && error && <span className="error">{errorMessage}</span>}
      </Form.Field>
    );
  }
};

export const RenderDateField = ({
  input,
  placeholder,
  openToDate,
  filterDate,
  clearable,
  defaultValue,
  label,
  disabled,
  meta: { touched, error }
}) => {
  const handleChange = date => {
    input.onChange(date ? moment(date).format('YYYY-MM-DD') : null);
  };

  // Format value for consistency
  if (input.value && input.value !== '') {
    input.value = moment(input.value).format('YYYY-MM-DD');
  }

  return (
    <Form.Field
      className={classnames({ error: touched && error !== undefined })}
    >
      <label>{label}</label>
      <DatePicker
        {...input}
        error={touched && error !== undefined}
        isClearable={clearable}
        placeholderText={placeholder}
        filterDate={filterDate}
        disabled={disabled}
        openToDate={openToDate}
        dateFormat="YYYY-MM-DD"
        onSelect={handleChange}
        selected={input.value ? moment(input.value, 'YYYY-MM-DD').toDate() : null}
      />
    </Form.Field>
  );
};

export const renderToggleInput = ({input, label, disabled, as: As = Input, ...props}) => {
   const handleChange = e => {
    input.onChange()
   }
  return <Toggle checked={input.value} onChange={input.onChange} value={input.value}  icons={false} name={label} disabled={disabled}/>
};
