import React, { Component } from "react";
import { Button, Divider, Form } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { RenderField } from "../common/renderField";

class AssignServicePlanToUserForm extends Component {
  render() {
    const { handleSubmit, loading, servicePlans, onSelect, site } = this.props;

    // Map servicePlans to dropdown options
    const options = servicePlans.map(plan => ({
      text: plan.text || "",
      value: plan.value || "",
    }));
 
     
    console.log("options=====", options);
    return (
      <Form onSubmit={handleSubmit}>
        <label>Service Plan</label>
        <Field
          name="service_plan_id"
          as={Form.Select}
          fluid
          lightweight
          options={options}
          component={RenderField}
          placeholder="Select Service Plan"
          width={16}
          onChange={onSelect} // Handle selection change
          disabled={options.length === 0} // Disable dropdown if options are empty
        />

        <Divider />
        {site === 4 && (
          <Button primary type="submit" loading={loading || options.length === 0}>
            Submit
          </Button>
        )}
      </Form>
    );
  }
}

// Connect to Redux Form
AssignServicePlanToUserForm = reduxForm({
  form: "assignServicePlanToUser",
  enableReinitialize: true, // Reinitialize form when props change
})(AssignServicePlanToUserForm);

export default AssignServicePlanToUserForm;
